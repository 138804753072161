import { FormEvent, FormEventHandler, useRef, useState } from 'react'
import {
    Button,
    Divider,
    Flex,
    Heading,
    HStack,
    Input,
    Text,
    ToastId,
    useToast,
} from '@chakra-ui/react'
import { signIn } from 'next-auth/react'
import { useRouter } from 'next/router'
import { routes } from 'routes'
import {
    useTargetAudience,
    useTargetAudienceContext,
} from 'containers/signin/context/TargetAudienceContext'
import { useParams } from 'containers/signin/hooks/useParams'
import { GoogleLogin } from 'components/buttons/GoogleLogin'
import { Values } from 'components/marketing'

const ContinueWith = () => {
    return (
        <HStack spacing="12px">
            <Divider borderColor="stroke.dark" />
            <Text variant="paragraph-s" whiteSpace="nowrap" color="beige.background">
                or
            </Text>
            <Divider borderColor="stroke.dark" />
        </HStack>
    )
}

const EmailLogin = ({
    onClick,
    loading,
}: {
    onClick: FormEventHandler<HTMLFormElement>
    loading: boolean
}) => {
    const [email, setEmail] = useState('')

    return (
        <form onSubmit={onClick}>
            <Flex gap={4} direction="column" alignItems="stretch">
                <Input
                    placeholder="Enter your email"
                    type="email"
                    name="email"
                    data-testid="email-login-input"
                    size={{ base: 'sm', sm: 'md' }}
                    isRequired
                    onChange={e => {
                        setEmail(e.target.value)
                    }}
                    value={email}
                    zIndex="docked"
                />
                <Button
                    type="submit"
                    data-testid="email-login-button"
                    isLoading={loading}
                    isDisabled={!email || loading}
                    zIndex="docked"
                    size={{ base: 'sm', sm: 'lg' }}
                >
                    Continue with email
                </Button>
            </Flex>
        </form>
    )
}

const SignInOptions = () => {
    const toast = useToast()
    const toastId = useRef<ToastId>()
    const router = useRouter()
    const [loading, setLoading] = useState(false)
    const targetAudienceContext = useTargetAudienceContext()
    const { inviteId } = useParams()
    const callbackUrl = (function () {
        if (targetAudienceContext.targetAudience === 'VENDOR') {
            return routes.auth.callbacks.vendor({
                vendorVerificationRequestId: targetAudienceContext.verificationRequestId,
            })
        } else {
            return routes.auth.callbacks.all({ inviteId })
        }
    })()

    const signInWithEmail = async (e: FormEvent<HTMLFormElement>) => {
        setLoading(true)

        e.preventDefault()
        const myFormData = new FormData(e.target as HTMLFormElement)
        const email = myFormData.get('email') as string
        //

        if (email) {
            try {
                const resp = await signIn('email', {
                    redirect: false,
                    callbackUrl,
                    email,
                })
                // Something went wrong
                if (resp?.error) {
                    throw new Error(resp?.error)
                }

                onSuccess(email)

                if (toastId.current) {
                    toast.close(toastId.current)
                }
            } catch (err) {
                console.error(err)
                if (toastId.current) {
                    toast.update(toastId.current, {
                        description: 'Unable to sign in',
                        status: 'error',
                    })
                }
            }
        }
    }

    const signInWithGoogle = () => {
        toastId.current = toast({ description: 'Loading...', status: 'loading' })
        signIn('google', {
            callbackUrl,
        })
    }

    const onSuccess = (email: string) => {
        router.push(routes.checkEmail(email))
        setLoading(false)
    }

    return (
        <Flex
            direction="column"
            alignItems={'stretch'}
            gap="32px"
            w="full"
            maxW="380px"
            mx="auto"
            sx={{
                gap: '20px',
                '@media screen and (min-height: 768px)': {
                    gap: '32px',
                },
            }}
        >
            {/* Google */}
            <GoogleLogin onClick={() => signInWithGoogle()} emailLoading={loading} />

            <ContinueWith />

            {/* Email */}
            <>
                <EmailLogin onClick={e => signInWithEmail(e)} loading={loading} />
            </>
        </Flex>
    )
}

const TARGET_AUDIENCE_HEADING = {
    COMPANY_OWNER: 'Get Started',
    INVESTOR: 'Sign in',
    VENDOR: `Let's get verified`,
    COMPANY_MEMBER: 'Get Started',
}

export function SignIn() {
    const targetAudience = useTargetAudience()
    const heading = TARGET_AUDIENCE_HEADING[targetAudience]

    return (
        <Flex
            w="full"
            direction="column"
            alignItems="center"
            justifyContent={'center'}
            flex="1"
            px={'24px'}
            sx={{
                '@media (max-height: 550px)': {
                    mb: '50px',
                },
            }}
        >
            <Flex
                maxW="464px"
                textAlign="center"
                flexDirection={'column'}
                alignItems={'center'}
                color="beige.background"
            >
                <Heading
                    as="h3"
                    color="beige.background"
                    textTransform="uppercase"
                    mb="20px"
                    display={{ base: 'block', md: 'none' }}
                    fontFamily="Deacon"
                    fontSize="40px"
                    zIndex="docked"
                >
                    {heading}
                </Heading>

                <Heading
                    as="h3"
                    variant="display-l"
                    color="beige.background"
                    textTransform="uppercase"
                    mb="20px"
                    display={{ base: 'none', md: 'block' }}
                    zIndex="docked"
                >
                    {heading}
                </Heading>
            </Flex>
            <Values />

            <SignInOptions />
        </Flex>
    )
}
